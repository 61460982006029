export default class componentMainTitle {
  constructor(title, subtitle = null, description = null) {
    this.title = title;
    this.subtitle = subtitle;
    this.description = description;
  }

  render() {
    const container = document.createElement("div");
    container.className = "max-w-xl mx-auto text-center xl:max-w-2xl mb-8";

    const titleElement = document.createElement("h2");
    titleElement.className =
      "text-3xl font-bold leading-tight sm:text-4xl xl:text-5xl mb-6";
    titleElement.textContent = this.title;

    const subtitleElement = document.createElement("p");
    subtitleElement.className = "mb-4";
    subtitleElement.textContent = this.subtitle;

    // Crear un contenedor para las descripciones
    const descriptionContainer = document.createElement("div");
    descriptionContainer.className = "mb-4";

    this.description?.forEach((text) => {
      const descriptionElement = document.createElement("p");
      descriptionElement.className = "mb-4"; // Ajusta el estilo según tus necesidades
      descriptionElement.textContent = text;
      descriptionContainer.appendChild(descriptionElement);
    });

    // Append elements to the container
    container.appendChild(titleElement);
    container.appendChild(subtitleElement);
    container.appendChild(descriptionContainer);

    return container;
  }
}
