// BackButton.js

export default class BackButton {
  constructor() {
    this.buttonContainer = document.createElement("div");
    this.buttonContainer.className = "sticky top-4 left-4";
  }

  render() {
    this.buttonElement = document.createElement("button");
    this.buttonElement.className =
      "inline-flex items-center border border-indigo-300 px-3 py-1.5 rounded-md text-indigo-500 hover:bg-indigo-50";
    this.buttonElement.setAttribute("x-on:click", `selectedForm = null`);
    this.buttonElement.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7 16l-4-4m0 0l4-4m-4 4h18"></path>
        </svg>
        <span class="hidden md:block ml-1 font-bold text-sm">Back</span>
    `;

    this.buttonContainer.appendChild(this.buttonElement);

    return this.buttonContainer;
  }
}
