import componentMainTitle from "../MainTitle";
import { calculadoraData } from "../../data/data";
import { algorithmHarrisBenedict } from "./algorithmHarrisBenedict";

export default class componentFormHarrisBenedict {
  constructor() {
    this.container = document.createElement("div");
    this.container.setAttribute("x-show", "selectedForm === 'HarrisBenedict' ");
    this.container.className = "md:w-3/5 max-w-full mx-auto p-4";
  }

  render() {
    const selectedData = calculadoraData.find(
      (item) => item.value === "HarrisBenedict"
    );

    const MainTitle = new componentMainTitle(
      selectedData.title,
      null,
      selectedData.description
    );
    this.container.appendChild(MainTitle.render());

    // Sección 1: Sexo y Edad
    const section1 = document.createElement("div");
    section1.className = "flex flex-wrap -mx-3 mb-2";

    const genderField = document.createElement("div");
    genderField.classList = "w-full md:w-1/2 px-3 mb-6 md:mb-0";
    genderField.innerHTML = `
    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="gender">Tu sexo:</label>
    <div class="relative">
        <select id="gender" name="gender-input" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option disabled selected>Selecciona tu sexo</option>
          <option value="h">Hombre</option>
          <option value="m">Mujer</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    `;
    section1.appendChild(genderField);

    const ageField = document.createElement("div");
    ageField.classList = "w-full md:w-1/2 px-3 mb-6 md:mb-0";
    ageField.innerHTML = `
    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="age">Tu edad en años:</label>
    <input id="age" type="number" name="age-input" placeholder="Introduce tu edad en años" min="10" max="99" maxlength="2" valid="true" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
    <span id="invalidAge" class="text-red-500" style="display: none;">Edad inválida</span>
    `;
    section1.appendChild(ageField);

    // Sección 2: Peso y Altura
    const section2 = document.createElement("div");
    section2.className = "flex flex-wrap -mx-3 mb-2";

    const weightField = document.createElement("div");
    weightField.classList = "w-full md:w-1/2 px-3 mb-6 md:mb-0";
    weightField.innerHTML = `
    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="weight">Tu peso en kg:</label>
    <input id="weight" type="number" name="weight-input" placeholder="Introduce tu peso en kg" min="30" max="250" maxlength="3" valid="true" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
    <span id="invalidWeight" class="text-red-500" style="display: none;">Peso inválido</span>
    `;
    section2.appendChild(weightField);

    const heightField = document.createElement("div");
    heightField.classList = "w-full md:w-1/2 px-3 mb-6 md:mb-0";
    heightField.innerHTML = `
    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="height">Tu altura en cm:</label>
    <input id="height" type="number" name="height-input" placeholder="Introduce tu altura en cm" min="50" max="400" maxlength="3" valid="true" class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
    <span id="invalidHeight" class="text-red-500" style="display: none;">Altura inválida</span>
    `;
    section2.appendChild(heightField);

    // Sección 3: Nivel de actividad física
    const section3 = document.createElement("div");
    section3.className = "mb-6";

    const activityField = document.createElement("div");
    activityField.innerHTML = `
    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="activity-level">Nivel de actividad física diaria:</label>
    <div class="relative">
        <select id="activity-level" name="activity-level" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option disabled selected>Selecciona una opción</option>
          <option value="sedentario">Sedentario: poco o nada de ejercicio al día</option>
          <option value="ligera">Actividad ligera: ejercicio ligero 1-3 días a la semana</option>
          <option value="moderada">Actividad moderada: ejercicio moderado 3-5 días a la semana</option>
          <option value="intensa">Actividad intensa: ejercicio intenso 6-7 días a la semana</option>
          <option value="muy intensa">Ejercicio muy intenso o trabajo físico diario</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    `;
    section3.appendChild(activityField);

    // Sección 4: Objetivo
    const section4 = document.createElement("div");
    section4.className = "mb-6";

    const goalField = document.createElement("div");
    goalField.innerHTML = `

    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="goal">Tu objetivo:</label>
    <div class="relative">
        <select id="goal" name="goal" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option disabled selected>Selecciona una opción</option>
          <option value="1">Aumentar masa muscular</option>
          <option value="2">Bajar grasa corporal</option>
          <option value="3">Mantener el peso actual</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    `;
    section4.appendChild(goalField);

    // Botón de cálculo
    const calculateBtn = document.createElement("button");
    calculateBtn.id = "calculate-btn";
    calculateBtn.type = "button";
    calculateBtn.textContent = "Calcular";
    calculateBtn.className =
      "w-full mt-4 bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75";

    // Resultado
    const resultSpan = document.createElement("span");
    resultSpan.id = "invalidForm";
    resultSpan.className = "text-red-500 text-center";
    resultSpan.style.display = "none";
    resultSpan.textContent =
      "Por favor, comprueba los campos inválidos o vacíos";

    // Añadir secciones al contenedor
    this.container.appendChild(section1);
    this.container.appendChild(section2);
    this.container.appendChild(section3);
    this.container.appendChild(section4);
    this.container.appendChild(calculateBtn);
    this.container.appendChild(resultSpan);

    // Result wrapper
    const resultWrapper = document.createElement("div");
    resultWrapper.id = "result-wrapper";
    resultWrapper.classList =
      "flex flex-col	border border-gray-950 rounded-lg p-4 mt-10 hidden";
    this.container.appendChild(resultWrapper);

    // Result title
    const resultParagraph = document.createElement("p");
    resultParagraph.className = "result";

    const showResult = document.createElement("strong");
    showResult.id = "showResult";

    resultParagraph.appendChild(showResult);
    resultWrapper.appendChild(resultParagraph);

    // Results section
    const resultTable = document.createElement("table");
    resultTable.className = "table";
    resultTable.id = "resultTable";
    resultTable.style.visibility = "hidden";

    const thead = document.createElement("thead");
    thead.className = "thead-light";

    const headerRow = document.createElement("tr");

    const thNivel = document.createElement("th");
    thNivel.scope = "col";
    thNivel.textContent = "Nivel";

    const thKcal = document.createElement("th");
    thKcal.scope = "col";
    thKcal.textContent = "kcal/día";

    headerRow.appendChild(thNivel);
    headerRow.appendChild(thKcal);
    thead.appendChild(headerRow);
    resultTable.appendChild(thead);

    const tbody = document.createElement("tbody");
    tbody.className = "text-center";

    const levels = ["light", "moderate", "aggressive", "very_aggressive"];
    levels.forEach((level) => {
      const row = document.createElement("tr");
      row.id = level;

      const tdResultType = document.createElement("td");
      tdResultType.className = "result-type";

      const tdResults = document.createElement("td");
      tdResults.className = "results";

      row.appendChild(tdResultType);
      row.appendChild(tdResults);
      tbody.appendChild(row);
    });

    resultTable.appendChild(tbody);

    resultWrapper.appendChild(resultTable);

    // Blog section
    const explicationContainer = document.createElement("div");
    this.container.appendChild(explicationContainer);

    const explicationTitle = document.createElement("div");
    explicationTitle.textContent = selectedData.explicationData.title;
    explicationTitle.classList = "mt-20 mb-4 text-3xl font-bold underline";
    explicationContainer.appendChild(explicationTitle);

    const explicationContent = document.createElement("div");
    explicationContainer.appendChild(explicationContent);

    selectedData?.explicationData?.content.forEach((el) => {
      let descriptionElement;
      switch (el.type) {
        case "title":
          descriptionElement = document.createElement("p");
          descriptionElement.className = "mt-8 mb-4 text-xl font-bold";
          descriptionElement.textContent = el.text;
          explicationContent.appendChild(descriptionElement);
          break;

        case "paragraph":
          if (Array.isArray(el.text)) {
            el.text.forEach((e) => {
              descriptionElement = document.createElement("p");
              descriptionElement.className = "mb-4";
              descriptionElement.textContent = e;
              explicationContent.appendChild(descriptionElement);
            });
          }
          break;

        case "ul":
          descriptionElement = document.createElement("ul");
          descriptionElement.classList = "mb-4 list-disc";
          el.text.forEach((item) => {
            const li = document.createElement("li");
            li.textContent = item;
            descriptionElement.appendChild(li);
          });
          explicationContent.appendChild(descriptionElement);
          break;

        case "image":
          descriptionElement = document.createElement("img");
          descriptionElement.src = el.src;
          descriptionElement.loading = "lazy";

          const figure = document.createElement("figure");
          figure.className = "mb-4";
          figure.appendChild(descriptionElement);

          if (el.caption) {
            const figcaption = document.createElement("figcaption");
            figcaption.className = "text-center mt-2 text-sm text-gray-600";
            figcaption.textContent = el.caption;
            figure.appendChild(figcaption);
          }

          explicationContent.appendChild(figure);
          break;

        default:
          break;
      }
    });

    const bibliographyContainer = document.createElement("div");
    bibliographyContainer.classList = "border border-gray-950 rounded-lg p-10";
    this.container.appendChild(bibliographyContainer);

    const bibliographyTitle = document.createElement("div");
    bibliographyTitle.classList = "text-xl font-bold";
    bibliographyTitle.textContent =
      selectedData.explicationData.bibliography.title;
    bibliographyContainer.appendChild(bibliographyTitle);

    const bibliographyContent = document.createElement("ol");
    bibliographyContent.classList = "list-decimal";
    selectedData?.explicationData?.bibliography?.content.forEach((text) => {
      const li = document.createElement("li");
      li.classList = "text-xs";
      li.textContent = text;
      bibliographyContent.appendChild(li);
    });
    bibliographyContainer.appendChild(bibliographyContent);

    // Script section
    document.addEventListener("DOMContentLoaded", () => {
      algorithmHarrisBenedict();
    });

    return this.container;
  }
}
