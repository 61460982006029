import { mainTitle, calculadoraData } from "../data/data";
import componentFormHarrisBenedict from "../components/Forms/HarrisBenedict";

import componentCard from "../components/Card";
import componentMainTitle from "../components/MainTitle";
import componentBackButton from "../components/BackButton";

export default class Layout {
  constructor() {
    this.container = document.createElement("div");
    this.container.className = "p-2 md:p-10 bg-slate-100";
    this.container.setAttribute("x-data", "{ selectedForm: null }");
  }

  render() {
    const mainZone = document.createElement("div");
    mainZone.setAttribute("x-show", "selectedForm === null");
    this.container.appendChild(mainZone);

    const MainTitle = new componentMainTitle(
      mainTitle.title,
      mainTitle.subtitle
    );
    mainZone.appendChild(MainTitle.render());

    const gridZone = document.createElement("div");
    gridZone.className = "grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3";
    mainZone.appendChild(gridZone);

    calculadoraData.forEach((data) => {
      if (data.disable) return;
      const card = new componentCard(data.title, data.subtitle, data.value);
      gridZone.appendChild(card.render());
    });

    const formZone = document.createElement("div");
    formZone.setAttribute("x-show", "selectedForm !== null");
    this.container.appendChild(formZone);

    const BackButton = new componentBackButton();
    formZone.appendChild(BackButton.render());

    const formHarrisBenedict = new componentFormHarrisBenedict();
    formZone.appendChild(formHarrisBenedict.render());

    return this.container;
  }
}
