export default class componentCard {
  constructor(title, subtitle, value, buttonCTA) {
    this.title = title;
    this.subtitle = subtitle;
    this.value = value;
    this.buttonCTA = buttonCTA || "Calcular ahora";
  }

  render() {
    const cardElement = document.createElement("div");
    cardElement.className =
      "relative bg-white shadow-xl rounded-xl h-full p-6 border border-gray-200 hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 flex flex-col justify-between";

    const titleElement = document.createElement("p");
    titleElement.className =
      "text-2xl mb-2 font-bold tracking-tight text-gray-900 dark:text-white";
    titleElement.textContent = this.title;

    const subtitleElement = document.createElement("p");
    subtitleElement.className =
      "font-normal text-gray-700 dark:text-gray-400 mb-8";
    subtitleElement.textContent = this.subtitle;

    const buttonElement = document.createElement("button");
    buttonElement.className =
      "mt-auto w-max self-end inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800";
    buttonElement.textContent = this.buttonCTA;
    buttonElement.setAttribute("x-on:click", `selectedForm = "${this.value}"`);

    cardElement.appendChild(titleElement);
    cardElement.appendChild(subtitleElement);
    cardElement.appendChild(buttonElement);

    return cardElement;
  }
}
