export const mainTitle = {
  title: "Calculadoras fitness automáticas",
  subtitle:
    "Descubre tus métricas de salud y rendimiento con nuestras distintas calculadoras gratuitas",
};

export const calculadoraData = [
  {
    title: "Calculadora Harris Benedict para calorías",
    subtitle: "Calcula cuántas calorías diarias gastas",
    description: [
      "Esta calculadora automática te indica cuáles son las calorías diarias que gastas (en total) según la fórmula de Harris-Benedict, la más popular y usada en la actualidad.",
      "Seguido de este resultado, se incluye una sección que te permite calcular cuántas calorías deberías comer aproximadamente para ganar peso (o ganar músculo) y para bajar de peso (o definir).",
    ],
    explicationData: {
      title: "Explicación e interpretación de la calculadora:",
      content: [
        {
          type: "title",
          text: "Uso de la calculadora de calorías Harris Benedict 2024",
        },
        {
          type: "paragraph",
          text: [
            "En el ámbito de la salud, la nutrición y el ejercicio, es fundamental comprender y calcular las calorías necesarias para mantener, aumentar o disminuir el peso corporal.",
            "La fórmula de Harris – Benedict, desarrollada en 1918, es ampliamente utilizada para estimar las calorías diarias necesarias para el metabolismo basal y la actividad física. Concretamente, la variante más utilizada en la actualidad es la propuesta por Mifflin y St. Jeor en 1990 a partir de una revisión de la original.",
            "A continuación, exploraremos en detalle esta fórmula, su utilidad y sus limitaciones, y también discutiremos alternativas y enfoques adicionales para deportistas y personas que buscan controlar su peso.",
          ],
        },
        { type: "title", text: "Fórmula de Harris Benedict explicada" },
        {
          type: "paragraph",
          text: [
            "La fórmula de Harris – Benedict representa el Ritmo Metabólico Basal (RMB), también denominado Tasa Metabólica Basal (TMB), que es la cantidad de energía necesaria para mantener las funciones vitales del organismo en reposo.",
            "La fórmula original, creada en 1918, tiene en cuenta el sexo, la edad, el peso y la estatura para calcular el metabolismo basal y, posteriormente, estimar el gasto calórico total.",
          ],
        },
        {
          type: "ul",
          text: [
            "Para hombres, la fórmula original de Harris-Benedict es la siguiente: RMB = 66 + (13.75 x peso en kg) + (5 x altura en cm) – (6.75 x edad en años)",
            "Para mujeres, la fórmula es ligeramente diferente: RMB = 655 + (9.56 x peso en kg) + (1.85 x altura en cm) - (4.68 x edad en años)",
          ],
        },
        {
          type: "paragraph",
          text: [
            "En 1990, Mifflin y St. Jeor revisaron la fórmula original de Harris – Benedict para mejorar la precisión y tener en cuenta las diferencias en el sexo y en la masa libre de grasa.",
            "A pesar de que en las fórmulas de Mifflin y St. Jeor no aparece de manera explícita el porcentaje de grasa corporal, su investigación comparó algunos métodos que sí lo tenían en cuenta. Las ecuaciones finales son regresiones lineales utilizando las mismas variables (sexo, edad y altura) que Harris-Benedict, simplemente, por simplicidad:",
          ],
        },
        {
          type: "ul",
          text: [
            "Para hombres: RMB = (10 x peso en kg) + (6.25 x altura en cm) – (5 x edad en años) + 5",
            "Para mujeres: RMB = (10 x peso en kg) + (6.25 x altura en cm) – (5 x edad en años) – 161",
          ],
        },
        {
          type: "paragraph",
          text: "Estas fórmulas son, en la actualidad, más utilizadas que las originales, aunque eso no significa que las de 1918 sean malas opciones. Es importante tener en cuenta que todas las fórmulas son aproximaciones generales y puede haber variaciones individuales.",
        },
        {
          type: "title",
          text: "¿Harris – Benedict es la mejor fórmula para calcular cuántas calorías gastas al día?",
        },
        {
          type: "paragraph",
          text: [
            "Si bien las fórmulas de Harris – Benedict y sus actualizaciones han sido ampliamente utilizada durante décadas, no se considera la mejor opción para calcular las calorías gastadas diariamente en todos los casos.",
            "La fórmula original fue desarrollada en una época en la que la población general tenía un nivel de actividad física más bajo que en la actualidad. Además, hay variaciones individuales en el metabolismo basal y la respuesta al ejercicio.",
            "Para los deportistas y personas físicamente activas, es posible que cualquiera de las dos opciones, las ecuaciones de Harris – Benedict originales o las revisadas por Mifflin y St. Jeor, subestimen sus necesidades calóricas.",
            "Además de estas fórmulas, hay otras que se consideran más precisas en deportistas, cuya masa muscular es superior a la media. Entre ellas, podemos citar las de Katch – McArdle (revisada por Cunningham en 1991), la de De Lorenzo (1999) o la de Tinsley (2019), todas ellas usando la masa libre de grasa como una de sus principales variables.",
            "Además, deberías saber que estas fórmulas se basan en regresiones lineales o polinómicas de los resultados de estudios del Gasto Energético Diario (GED) medido por calorimetría indirecta, que es el método de referencia para evaluarlo, un método mucho más preciso y ajustado a cada persona.",
          ],
        },
        {
          type: "title",
          text: "¿Para qué sirve saber tus calorías de mantenimiento?",
        },
        {
          type: "paragraph",
          text: [
            "Conocer tus calorías de mantenimiento, es decir, la cantidad de calorías necesarias para mantener tu peso corporal actual, es importante para diferentes propósitos.",
            "Saber tus calorías de mantenimiento te permite establecer una base para el control de peso y la educación nutricional. Si no sabemos nuestro Gasto Energético Diario es muy difícil saber qué necesitamos, qué elecciones y conductas, tanto nutricionales como alimentarias, nos ayudarán a mantener, e incluso mejorar nuestro estado de salud.",
            "Si consumes la misma cantidad de calorías que gastas, tu peso se mantendrá estable, pero si no mides lo uno y lo otro, es cuestión de azar que esto acabe pasando.",
            "Si deseas perder peso, puedes crear un déficit calórico al consumir menos calorías de las que gastas. Por otro lado, si deseas ganar peso, puedes crear un superávit calórico al consumir más calorías de las que gastas.",
            "Tanto el mantenimiento de peso corporal, como el descenso o el aumento, pueden implicar un cambio en la composición corporal si se realiza un determinado tipo de entrenamiento y ajustando los nutrientes en base a ello.",
          ],
        },
        {
          type: "title",
          text: "¿En qué nos basamos para determinar las calorías para subir o bajar de peso?",
        },
        {
          type: "paragraph",
          text: [
            "Determinar las calorías necesarias para subir o bajar de peso implica considerar varios factores, incluyendo tus objetivos, tu nivel de actividad física, tu experiencia de entrenamiento y tu metabolismo individual.",
            "Para perder peso, generalmente se recomienda crear un déficit calórico que dependerá de tu propio contexto. No todas las personas necesitan un mismo déficit calórico porque lo que para una puede ser llevadero, para otra puede ser una lucha constante poco deseable.",
            "La verdad es que fijar un déficit sin atender al contexto de cada persona es una locura porque pueden darse momentos de ansiedad, falta de descanso y otros problemas, incluso familiares y sociales, por motivo de la relación con la comida.",
            "Así que, tenemos que atender a lo estresada que una persona tenga su día a día, su nivel de responsabilidad en el trabajo, su preparación psicológica (¿ha hecho algo así previamente?), su composición corporal, etc. Los rangos se sitúan entre el 10% y el 25% de déficit respecto al Gasto Energético Diario (Figura 1).",
            "Los déficits más agresivos, probablemente estén destinados a personas con más porcentaje de grasa corporal, menos estrés y más preparación psicológica, mientras que los déficits más conservadores quedan para personas con mucho estrés, porcentaje bajo de grasa corporal y mala preparación psicológica o contexto actual difícil.",
            "Lo más importante durante el proceso para perder grasa es que se haga con adherencia, sin sufrir en exceso (algo costará), con buen estado de ánimo, manteniendo la máxima cantidad de masa muscular posible y teniendo la energía suficiente para hacer todas las tareas del día y el entrenamiento.",
          ],
        },
        {
          type: "image",
          src: "https://fitgeneration.es/wp-content/uploads/2023/06/Imagen29.png",
          caption:
            "Figura 1. Déficit diario llevadero según el estilo de vida y el porcentaje graso.",
        },
        {
          type: "paragraph",
          text: [
            "De esta forma, una pérdida de peso saludable se considera aquella que permite mantener en el tiempo (meses) una pérdida semanal del 0.5 – 1.0% del peso corporal. Esto se puede lograr reduciendo la ingesta calórica y/o aumentando la actividad física diaria.",
            "Por otro lado, para aumentar de peso corporal, generalmente deseable en forma de masa muscular más que como grasa, se necesita crear un superávit calórico que permita consumir más calorías de las que se gastan. Esto se logra aumentando la ingesta calórica y realizando ejercicio de fuerza habitualmente para estimular el crecimiento muscular.",
            "Cuánto se incremente la ingesta calórica diaria dependerá de la experiencia de entrenamiento, la carga de entrenamiento global (intensidad, volumen y frecuencia) y tu capacidad de recuperación. Generalmente, se suele diferenciar entre principiantes, intermedios y avanzados para recomendar un determinado rango de superávit aconsejable, además de una distribución óptima de macronutrientes (Tabla 1).",
          ],
        },
        {
          type: "image",
          src: "https://fitgeneration.es/wp-content/uploads/2023/06/Imagen30.jpg",
          caption:
            "Tabla 1. Recomendaciones generales de superávits diarios y distribución de macronutrientes para incrementar masa muscular a buen ritmo",
        },
        {
          type: "paragraph",
          text: [
            "Una buena tasa de crecimiento muscular supondría ir aumentando el peso corporal entre 200 y 300 gramos cada una o dos semanas siguiendo estas recomendaciones.",
            "Sin embargo, es importante tener en cuenta que estos son solo orientaciones generales y las necesidades calóricas pueden variar según cada persona. Además, la calidad de los alimentos consumidos también es esencial para mantener una buena salud. Se recomienda centrarse en alimentos nutritivos y equilibrados, en lugar de simplemente contar calorías.",
          ],
        },
        { type: "title", text: "Resumen y conclusiones" },
        {
          type: "paragraph",
          text: [
            "En conclusión, las fórmulas de Harris – Benedict son adecuadas para calcular las calorías diarias necesarias para el metabolismo basal y, consecuentemente, a partir de los factores de actividad, del Gasto Energético Diario.",
            "Sin embargo, puede haber variaciones individuales y limitaciones en su aplicación, especialmente para deportistas y personas físicamente activas. En estos casos, es importante considerar alternativas, como las fórmulas de Mifflin y St Jeor, más actualizadas, así como otras más específicas para deportistas que tengan en cuenta la masa libre de grasa.",
            "Debemos recordar que hay herramientas y métodos, como la calorimetría indirecta, que permiten obtener estimaciones más precisas.",
            "Sea como sea, estas ecuaciones son útiles, y te ayudarán a conocer tus calorías de mantenimiento y ajustar tu ingesta calórica en función de tus objetivos es fundamental para el control de peso y la salud en general. Los resultados son aproximados y deberás hacer ajustes en función de cómo sea la evolución. Recuerda que también se deben considerar otros factores, como la calidad de los alimentos y el equilibrio nutricional, para mantener una alimentación saludable y sostenible.",
          ],
        },
      ],
      bibliography: {
        title: "Bibliografía y referencias",
        content: [
          "Harris, J. A., & Benedict, F. G. (1918). A biometric study of human basal metabolism. Proceedings of the National Academy of Sciences, 4(12), 370-373.",
          "Katch, F. I., & McArdle, W. D. (1975). Validity of body composition prediction equations for college men and women. The American journal of clinical nutrition, 28(2), 105-109.",
          "Mifflin, M. D., St Jeor, S. T., Hill, L. A., Scott, B. J., & Duncan, M. A. (1990). A new predictive equation for resting energy expenditure in healthy individuals. The American Journal of Clinical Nutrition, 51(2), 241-247.",
          "Cunningham, J. J. (1991). A reanalysis of the energy expenditure and body composition of men and women. Journal of Applied Physiology, 71(6), 2358-2366.",
          "De Lorenzo, A., et al. (1999). Total body water by bioimpedance analysis: a comparison with the deuterium dilution method. European Journal of Clinical Nutrition, 53(3), 247-250.",
          "Jagim, A. R., Camic, C. L., Askow, A., Luedke, J., Erickson, J., Kerksick, C. M., ... & Oliver, J. M. (2019). Sex differences in resting metabolic rate among athletes. The Journal of Strength & Conditioning Research, 33(11), 3008-3014.",
          "Tinsley, G. M., & Smith-Ryan, A. E. (2019). Nutritional strategies to support the metabolic adaptation to weight loss. Nutrition Reviews, 77(1), 62-73.",
          "Strock, N. C., Koltun, K. J., Southmayd, E. A., Williams, N. I., & De Souza, M. J. (2020). Indices of resting metabolic rate accurately reflect energy deficiency in exercising women. International Journal of Sport Nutrition and Exercise Metabolism, 30(1), 14-24.",
          "Fields, J. B., Magee, M. K., Jones, M. T., Askow, A. T., Camic, C. L., Luedke, J., & Jagim, A. R. (2022). The accuracy of ten common resting metabolic rate prediction equations in men and women collegiate athletes. European Journal of Sport Science, 1-10.",
          "Sordi, A. F., Mariano, I. R., Silva, B. F., & Branco, B. H. M. (2022). Resting metabolic rate in bodybuilding: Differences between indirect calorimetry and predictive equations. Clinical Nutrition ESPEN, 51, 239-245.",
          "Siedler, M. R., De Souza, M. J., Albracht-Schulte, K., Sekiguchi, Y., & Tinsley, G. M. (2023). The Influence of Energy Balance and Availability on Resting Metabolic Rate: Implications for Assessment and Future Research Directions. Sports Medicine, 1-20.",
        ],
      },
    },
    value: "HarrisBenedict",
    disable: false,
  },
  {
    title: "Calculadora 1RM para sentadilla",
    subtitle: "Calcula cuál es tu RM aproximado en sentadilla.",
    value: "1rm_squat",
    disable: false,
  },
  {
    title: "Calculadora VO2Max",
    subtitle:
      "Calcula la capacidad del organismo para transportar oxígeno a tus músculos",
    value: "vo2max",
    disable: false,
  },
  {
    title: "Calculadora GET (gasto energético diario)",
    subtitle:
      "Calcula la cantidad aproximada de kcals que quema tu cuerpo en un día",
    value: "get",
    disable: false,
  },
  {
    title: "Calculadora RMR (tasa metabólica en reposo)",
    subtitle: "Descubre cuántas calorías gasta tu cuerpo en reposo",
    value: "rmr",
    disable: false,
  },
  {
    title: "Test de Ruffier",
    subtitle: "Evalúa tu salud cardiovascular.",
    value: "ruffier",
    disable: false,
  },
  {
    title: "Test de escalón (Harvard)",
    subtitle:
      "Calcula la capacidad cardiovascular y la capacidad de recuperación de tu cuerpo",
    disable: false,
  },
];
